import * as $ from 'jquery';
import AnimateOnScroll from './animate-on-scroll';
import { Company } from './company';
import { CompanySearch } from './company-search';
import { Contextual } from './contextual';
import { Footer } from './footer';
import { Header } from './header';
import { GoogleMap } from './plugins/map';
const Cookies = require('js-cookie');
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';
const Instafeed = require("./instafeed");
import Lightbox from './lightbox/lightbox';
import { PriceRange } from './price-range';
require('./printsModal');
import { PropertiesController } from './properties-controller';
import Radio from './radio';
import { Search } from './search/search';
import { Select } from './select';
import SlideshowHover from './plugins/slideshow-hover';
import './chat-contextual';
import './listing';
require("../sass/app.scss");
import "../app.css";
Company();
Header.init();
Footer();
require('./listing-slides');
require('slick-carousel');
const $membership_form_btn = document.querySelector('[href="#typeform-mmEsNZ2R"]');
const $membership_form_btn_fr = document.querySelector('[href="#typeform-k1BZeehh"]');
const membership_form_id = 'mmEsNZ2R';
const membership_form_id_fr = 'k1BZeehh';
const guest_form_id = 'S9rTsXVi';
const guest_form_id_fr = 'p0MswCeb';
if ($membership_form_btn) {
    const { open, close, toggle, refresh } = createPopup(membership_form_id);
    $('[href="#typeform-mmEsNZ2R"]').click((e) => {
        toggle();
        e.preventDefault();
        return false;
    });
    const { toggle: toggleGuestForm } = createPopup(guest_form_id);
    $('[href="#typeform-S9rTsXVi"]').click((e) => {
        toggleGuestForm();
        e.preventDefault();
        return false;
    });
}
else if ($membership_form_btn_fr) {
    const { open, close, toggle, refresh } = createPopup(membership_form_id_fr);
    $(`[href="#typeform-${membership_form_id_fr}"]`).click((e) => {
        toggle();
        e.preventDefault();
        return false;
    });
    const { toggle: toggleGuestForm } = createPopup(guest_form_id_fr);
    $(`[href="#typeform-${guest_form_id_fr}"]`).click((e) => {
        toggleGuestForm();
        e.preventDefault();
        return false;
    });
}
;
window.jQuery = $;
window.JQuery = $;
window.$ = $;
if (module.hot) {
    module.hot.accept();
}
$('.js-slider-spaces').slick({
    infinite: false,
    arrows: true,
    dots: false,
    prevArrow: '<div class="-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"/></svg></div>',
    nextArrow: '<div class="-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"/></svg></div>',
    slidesToShow: 2,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                infinite: false,
                variableWidth: true,
                arrows: false,
                slidesToShow: 1
            }
        }
    ]
});
$('.js-slider-team').slick({
    infinite: false,
    arrows: true,
    dots: false,
    variableWidth: false,
    prevArrow: '<div class="-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"/></svg></div>',
    nextArrow: '<div class="-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"/></svg></div>',
    slidesToShow: 5,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                infinite: false,
                variableWidth: false,
                arrows: true,
                slidesToShow: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                infinite: false,
                variableWidth: false,
                arrows: false,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 400,
            settings: {
                infinite: false,
                variableWidth: true,
                arrows: false,
                slidesToShow: 1
            }
        }
    ]
});
$('.js-slider-visit').slick({
    arrows: true,
    dots: false,
    prevArrow: '<div class="-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"/></svg></div>',
    nextArrow: '<div class="-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"/></svg></div>',
    slidesToShow: 1,
    infinite: false
});
function initApp() {
    const Lb = new Lightbox();
    const agentSearch = new CompanySearch($('.js-brokers'), $('.js-search-agents input'), $(".js-select"));
    const teamSearch = new CompanySearch($('.js-teams'), $('.js-search-agents input'), $(".js-select"));
    const priceRange = new PriceRange($('.js-price-range'));
    const propertiesController = new PropertiesController($('.js-properties-search'), $('.js-pagination'), $('.js-positions'), $('.js-properties'), $(".js-properties-map"));
    const searchHeader = new Search($('.js-search-header'));
    const searchHome = new Search($('.js-search-home'));
    $('.js-contextual').each((i, el) => {
        new Contextual($(el));
    });
    $('.js-select').each((i, el) => {
        new Select($(el));
    });
    const mapFooter = new GoogleMap($('#map-canvas'), $(''), $(`.js-offices`), { zoom: 9 });
    const mapListing = new GoogleMap($('#listing__gmap'), $('.js-properties'), $('.js-positions'), {
        zoom: 12
    });
    const mapProperties = new GoogleMap($('#properties__gmap'), $('.js-properties'), $('.js-positions'));
    Radio();
    SlideshowHover();
    $('.js-show-all').click(function () {
        $(this).parent().find('ul > li').fadeIn();
    });
    $('.js-scroll-to, [data-scroll-target]').click(function (e) {
        const $target = $($(this).data('target') ||
            $(this).data('scroll-target') ||
            `[data-id=${$(this).attr('href').split("#").pop()}]`);
        if (!$target.length)
            return;
        e.preventDefault();
        var bodyRect = document.body.getBoundingClientRect(), elemRect = $target[0].getBoundingClientRect(), offset = elemRect.top - bodyRect.top;
        $('html, body').animate({
            scrollTop: offset - 36
        }, 500);
    });
    if (window.location.hash) {
        const $target = $(`[data-id=${window.location.hash.replace('#', '')}]`);
        if ($target.length) {
            var bodyRect = document.body.getBoundingClientRect(), elemRect = $target[0].getBoundingClientRect(), offset = elemRect.top - bodyRect.top;
            $('html, body').animate({
                scrollTop: offset - 36
            }, 500);
        }
    }
    AnimateOnScroll.init();
}
initApp();
var animation = false, animationstring = 'animation', keyframeprefix = '', domPrefixes = 'Webkit Moz O ms Khtml'.split(' '), pfx = '', elm = document.createElement('div');
if (elm.style.animationName !== undefined) {
    animation = true;
}
if (!animation) {
    $('html').removeClass('canAnimate');
}
$('.js-fb-share').click(function () {
    window.FB.ui({
        method: 'share',
        href: window.location.href,
    }, () => { });
});
let agent_phone = $("[data-phone]");
if (agent_phone.length) {
    let tel = 'tel:' + agent_phone.data('phone');
    $('#js-phone').attr('href', tel);
}
const $insta = $('#instafeed');
if ($insta.length) {
    let accessToken;
    let userId;
    if ($insta.data('token')) {
        accessToken = $insta.data('token');
        userId = $insta.data('user-id');
    }
    else {
        accessToken = window.instaToken;
        userId = '1545050390';
    }
    var feed = new Instafeed({
        template: '<a href="{{link}}" target="_blank"><img src="{{image}}" /></a>',
        accessToken,
        get: 'user',
        clientId: '369896860637565',
        limit: 8,
        resolution: 'standard_resolution',
        userId
    });
    feed.run();
}
$('.slick-container-reviews').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
});
if ($('.js-banner').length) {
    $('.app-header').css('top', $('.js-banner').outerHeight(true));
    setTimeout(() => {
        $('.app-header').css('top', $('.js-banner').outerHeight(true));
    }, 1000);
}
$('.js-close-banner').click(() => {
    $('.js-banner').remove();
    $('.app-header').css('top', 0);
    Cookies.set('banner', '1');
});
if ($('.js-listing-toggle-text').height() > $('.js-listing-toggle-container').height()) {
    $('.js-listing-toggle-wrapper').addClass("-show");
}
$('.js-listing-toggle-button').click(() => {
    $('.js-listing-toggle-wrapper').addClass("-active");
});
