import * as $ from 'jquery';
import { UniqueId } from '../unique-id';
export class SearchBase {
    constructor($container, endpoint) {
        this.$instance = $();
        this.loader = '<div class="loader"></div>';
        this.$container = $container;
        this.endpoint = endpoint;
        this.$container.on('keyup', (e) => {
            this.onChange(e);
        });
        this.$container.on('click', '.js-close', () => {
            this.$container.find('input').val('');
            clearTimeout(this.search_timeout);
            this.$container.removeClass('-has-text -searching');
            this.$instance.remove();
            this.$instance = $('');
            this.$container.trigger('search-closed');
        });
        this.$container.find('input').val('');
    }
    onChange({ target }, timeout = 500) {
        clearTimeout(this.search_timeout);
        let val = $(target).val();
        if (val !== '') {
            this.$container.addClass("-has-text");
        }
        else {
            this.$container.removeClass("-has-text");
        }
        this.renderLoader();
        this.search_timeout = window.setTimeout(() => {
            this.search(val || "");
            if (val === "") {
                this.$container.removeClass('-has-text -searching');
                this.$instance.remove();
                this.$instance = $('');
                this.$container.trigger('search-cleared');
            }
        }, timeout);
    }
    renderLoader() {
        this.$container.addClass("-searching");
    }
    render() {
        if (this.$container.find('input').val() === '')
            return;
        let res = this.prepareRender();
        if (this.$instance.length) {
            this.$instance.html(res);
        }
        else {
            this.$instance = this.makeInstance(res);
            this.$container.append(this.$instance);
        }
    }
    search(search) {
        search = search.slice(0, 100);
        if (!search.length)
            return;
        this.last_request_id = UniqueId();
        this.$container.append(this.$instance);
        $.get(this.endpoint, { s: search, id: this.last_request_id })
            .done((res) => {
            if (res.id === this.last_request_id) {
                this.searchDone(res);
                this.$container.removeClass('-searching');
            }
        })
            .fail(e => {
            if (process.env.NODE_ENV !== 'production') {
                console.log(e);
            }
            this.$container.removeClass('-searching');
        });
    }
}
