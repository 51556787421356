import * as $ from 'jquery';
export const Company = () => {
    const $lis = $('.js-company-filter li');
    $('.js-company-filter').on('click', 'li', function () {
        let selector = $(this).data('selector');
        $('.js-teams-header').fadeOut();
        if (selector) {
            $lis.each((i, $li) => {
                if ($($li).data('selector') === selector) {
                    $($li).addClass('active');
                }
                else {
                    $($li).removeClass('active');
                    $(`${$($li).data('selector')}`).hide();
                }
            });
            $(`${selector}`).fadeIn();
        }
        else {
            $('.js-teams-header').fadeIn();
            $lis.each((i, $li) => {
                if ($($li).data('selector')) {
                    $(`${$($li).data('selector')}`).fadeIn();
                    $($li).removeClass('active');
                }
                else {
                    $($li).addClass('active');
                }
            });
        }
    });
    $('.js-company-filter li.active').trigger('click');
};
