import { Agent, Block, Property, Wrap } from './templates';
import { Lang } from './lang';
import { SearchBase } from '../search-base';
import * as $ from 'jquery';
export class Search extends SearchBase {
    constructor($container, endpoint = "/search") {
        super($container, endpoint);
        this.agents = [];
        this.$instance = $();
        this.properties = [];
        this.teams = [];
        this.$container = $container;
        let locale = document.documentElement.lang;
        this.lang = Lang[locale];
        if (!$('.app-nav__list.-social').is(':visible') || $('.js-burger').is(':visible')) {
            $container.find('input').attr('placeholder', $container.data('placeholder-mobile'));
        }
    }
    makeInstance(res) {
        return $(Wrap(res));
    }
    prepareRender() {
        let is_empty = true;
        let types = ['agents', 'teams', 'properties'];
        let res = types.reduce((render, type) => {
            if (this[type].length > 0) {
                let els = this[type].reduce((acc, el) => {
                    return type === "properties"
                        ? acc + Property(el)
                        : acc + Agent(el);
                }, '');
                if (els.length)
                    is_empty = false;
                return render + Block(this.lang[type], els, type);
            }
            return render;
        }, '');
        if (is_empty)
            res = Block(this.lang.empty, '', 'empty');
        return res;
    }
    searchDone({ agents, properties, teams }) {
        if (process.env.NODE_ENV !== 'production') {
            console.log(properties);
        }
        this.agents = agents;
        this.properties = properties;
        this.teams = teams;
        this.render();
    }
}
