import * as $ from 'jquery';
const queryString = require('query-string');
function init() {
    let qs = queryString.parse(location.search);
    Object.keys(qs).forEach(key => {
        $(`input[name=${key}][value="${qs[key]}"]`).prop('checked', true);
    });
}
export default () => {
    init();
    window.addEventListener("popstate", init);
};
