import * as $ from 'jquery';
const queryString = require('query-string');
export class Select {
    constructor($container) {
        this.dropdown_width = "100%";
        this.default = '';
        this.is_open = false;
        this.selected = {
            label: '',
            value: ''
        };
        this.$container = $container;
        this.default = $container.data('default');
        this.label_postfix = $container.data('label-postfix');
        this.$label = this.$container.find('label');
        this.$select = this.$container.find('select');
        $('body').on('click', this.onBodyClick.bind(this));
        this.dropdown_width = this.$container.data('width') || this.dropdown_width;
        this.$container.on('click', this.onClick.bind(this));
        this.$container.on('change', this.onChange.bind(this));
        this.label_default = this.$select.find(`option[value='']`).text();
        this.initFromQs();
        this.selected = {
            label: this.$select.find(':selected').text(),
            value: this.$select.val()
        };
        this.handlePostfix();
        this.initDropdown();
        this.render();
        window.addEventListener("popstate", this.initFromQs.bind(this));
    }
    handlePostfix() {
        if (this.label_postfix && this.selected.value !== '') {
            this.selected.label = `${this.selected.label} ${this.label_postfix}`;
        }
    }
    initFromQs() {
        let qs = queryString.parse(location.search);
        let val = qs[this.$select.attr('name')];
        if (val) {
            this.$select.val(val);
        }
        else {
            this.$select.val(this.default);
        }
    }
    initDropdown() {
        let $lis = [];
        let self = this;
        this.$container.find('option').each(function () {
            let $this = $(this);
            let label = $this.attr('value') === '' ? '-' : $this.text();
            let $li = $(`<li data-value="${$this.attr('value')}">${label}</li>`);
            if ($this.text() === self.selected.label) {
                $li.addClass('active');
            }
            $lis.push($li);
        });
        let $ul = $('<ul class="js-dropdown select__dropdown"></ul>');
        $ul.append($lis);
        this.$container.append($ul);
        $ul.css('width', this.dropdown_width);
        $ul.on('click', 'li', function (e) {
            self.onDropdownClick(e, this);
        });
        this.$dropdown = $ul;
    }
    onChange(e) {
        this.selected = {
            label: this.$select.find(':selected').text(),
            value: this.$select.val()
        };
        this.handlePostfix();
        this.$container.trigger('change:select', [{
                name: this.$select.attr('name'),
                value: this.selected.value
            }]);
        let self = this;
        this.$dropdown.find('li').each(function () {
            let $this = $(this);
            if ($this.data('value') === self.selected.value) {
                $this.addClass('active');
            }
            else {
                $this.removeClass('active');
            }
        });
        this.$dropdown.hide();
        this.render();
    }
    onBodyClick(e) {
        this.$dropdown.hide();
    }
    onClick(e) {
        e.stopPropagation();
        if (!this.$dropdown.is(':visible')) {
            $(".js-dropdown").hide();
        }
        this.$dropdown.toggle();
    }
    onDropdownClick(e, el) {
        e.stopPropagation();
        let $this = $(el);
        let value = $this.data('value');
        let $sel = $($this.parent().parent().find('select'));
        $sel.val(value);
        $sel.trigger('change');
    }
    render() {
        this.$label.text(this.selected.label);
        if (this.selected.value === "") {
            this.$label.addClass('-default');
        }
        else {
            this.$label.removeClass('-default');
        }
    }
}
