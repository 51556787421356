export const Lang = {
    en: {
        agents: {
            title: 'People'
        },
        empty: {
            title: "No results"
        },
        properties: {
            title: "Properties"
        },
        teams: {
            title: "Teams"
        }
    },
    fr: {
        agents: {
            title: 'Équipe'
        },
        empty: {
            title: "Aucun résultat trouvé"
        },
        properties: {
            title: "Propriétés"
        },
        teams: {
            title: "Équipes"
        }
    }
};
