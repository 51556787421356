import * as $ from 'jquery';
export class Contextual {
    constructor($container) {
        this.open = false;
        this.$container = $container;
        this.$contextual = $container.find('.js-contextual-box');
        $container.click((e) => {
            e.stopPropagation();
            if ($(e.target).closest('.js-contextual-box').length)
                return;
            this.open = !this.open;
            $container.toggleClass('active');
        });
        $('body').click(e => {
            this.open = false;
            $container.removeClass('active');
        });
        this.$contextual.on('click', e => e.stopPropagation);
    }
}
