import * as $ from 'jquery';
export class CompanySearch {
    constructor($container, $input, $select) {
        this.$container = $container;
        this.$input = $input;
        this.$select = $select;
        if (!$container.length)
            return;
        this.$agents = this.$container.children('div');
        this.$filtered_agents = this.$agents.clone();
        this.$container.empty().append(this.$filtered_agents);
        this.$input.on('keyup', (e) => this.filterAgents(e));
        this.$select.on('change', (e) => this.filterAgents(e));
    }
    filterAgents({ target }, timeout = 500) {
        let val = this.$input.val();
        this.$filtered_agents = this.$agents.clone();
        this.$filtered_agents = this.$agents.clone().filter((index, el) => $(el).find('.js-title').text().toLowerCase().indexOf(val.toLowerCase()) !== -1);
        let $prev = this.$container.prev();
        if ($prev.hasClass("agents__title")) {
            if (this.$filtered_agents.length) {
                $prev.fadeIn();
            }
            else {
                $prev.fadeOut();
            }
        }
        clearTimeout(this.filtering_timeout);
        this.render();
        this.filtering_timeout = window.setTimeout(() => {
            if (!this.$input || !this.$input.offset || !this.$input.offset())
                return;
            $('html, body').animate({
                scrollTop: this.$input.offset().top - 75
            }, 500);
        }, 300);
    }
    filterLocation($agents) {
        let location = this.$select.find('select').val();
        return $agents;
    }
    render() {
        this.$container.empty().append(this.$filtered_agents);
    }
}
