import * as $ from 'jquery';
import MarkerClusterer from '@googlemaps/markerclustererplus';
export const MCoords = {
    lat: 45.437725,
    lng: -73.8265302
};
export class GoogleMap {
    constructor($container, $properties, $markers_parent, opts = {}) {
        this.markers = [];
        this.opts = {
            center: new google.maps.LatLng(45.437725, -73.8265302),
            scrollwheel: false,
            zoom: 8
        };
        this.opts = Object.assign(Object.assign({}, this.opts), opts);
        this.$container = $container;
        this.$markers_parent = $markers_parent;
        this.$properties = $properties;
        if ($container.data('lng') && $container.data('lat')) {
            this.opts.center = new google.maps.LatLng($container.data('lat'), $container.data('lng'));
        }
        if ($container.length) {
            google.maps.event.addDomListener(window, 'load', this.fire.bind(this));
        }
        this.$markers_parent.parent().on("positions-refresh", () => {
            this.markers.forEach(marker => {
                marker.setMap(null);
            });
            this.markers = [];
            this.handleMarkers();
        });
        this.$markers_parent.parent().on("positions-clear", () => {
            this.markers.forEach(marker => {
                marker.setMap(null);
            });
            this.markers = [];
        });
    }
    addClickListener(marker, id) {
        google.maps.event.addListener(marker, 'click', () => {
            const $pos = $('[data-id=' + id + ']');
            if ($pos.data('link')) {
                window.open($pos.data('link'), "_blank");
                return;
            }
            $('html, body').animate({
                scrollTop: $("#id-" + id).offset().top - 100
            }, 800);
            this.$properties.find('.active').removeClass('active');
            $("#id-" + id).addClass('active');
        });
    }
    fire() {
        this.map = new google.maps.Map(document.querySelector(`#${this.$container.attr('id')}`), this.opts);
        this.handleMarkers();
    }
    handleMarkers() {
        const bounds = new google.maps.LatLngBounds();
        let $lis = this.$markers_parent.children('li');
        const useClustering = $lis.length > 10;
        $lis.each((i, el) => {
            let $el = $(el);
            let position = this.opts.center;
            if ($el.data('lng') && $el.data('lat')) {
                position = new google.maps.LatLng($el.data('lat'), $el.data('lng'));
            }
            var marker = new google.maps.Marker({
                icon: " ",
                position,
                label: {
                    className: $(el).data('inactive') ? 'mapMarkerWhite' : 'mapMarker',
                    color: $(el).data('inactive') ? 'black' : 'white',
                    text: $(el).data('price')
                },
                title: $(el).data('title')
            });
            bounds.extend(position);
            if (!useClustering) {
                marker.setMap(this.map);
            }
            this.addClickListener(marker, $(el).data('id'));
            this.markers.push(marker);
        });
        this.markers.forEach((m, i) => {
            var existingMarker = this.markers[i];
            var pos = existingMarker.getPosition();
            const hasConflict = this.markers.find((m2, j) => {
                var _a;
                return i !== j && ((_a = m2.getPosition()) === null || _a === void 0 ? void 0 : _a.equals(pos));
            });
            if (hasConflict) {
                var newLat = pos.lat() + (Math.random() - .5) / 1500;
                var newLng = pos.lng() + (Math.random() - .5) / 1500;
                m.setPosition(new google.maps.LatLng(newLat, newLng));
            }
        });
        if (this.map) {
            this.map.fitBounds(bounds);
            if (useClustering) {
                new MarkerClusterer(this.map, this.markers, {
                    clusterClass: "mapCluster"
                });
            }
        }
    }
}
