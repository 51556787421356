import * as $ from 'jquery';
export default class Lightbox {
    constructor() {
        this.$lb = $('#js-lb');
        if (this.$lb.data('show-on-load') !== undefined) {
            this.openLb();
        }
        this.$lbTrigger = $('.js-lb-trigger, a[href="#lightbox"]');
        if (this.$lb.length && this.$lbTrigger.length) {
            this.$lbTrigger.click(this.openLb.bind(this));
            this.$lb.on('click', '.js-close-lb', this.closeLb.bind(this));
            this.$lb.on('click', (e) => {
                if (!e.target.closest('.form-box')) {
                    this.closeLb.call(this);
                }
            });
        }
    }
    closeLb() {
        $('body').css({
            overflow: 'visible',
            height: 'auto'
        });
        this.$lb.removeClass('active');
        setTimeout(() => {
            this.$lb.css('display', 'none');
        }, 300);
        $('body').scrollTop(this.scroll);
    }
    openLb(e) {
        e && e.preventDefault();
        this.scroll = window.pageYOffset;
        $('body').css({
            overflow: 'hidden',
            height: '100%'
        });
        this.$lb.css('display', 'block');
        setTimeout(() => {
            this.$lb.addClass('active');
        }, 300);
    }
}
