const queryString = require('query-string');
export class PriceRange {
    constructor($container) {
        this.$container = $container;
        this.$label = $container.find('.js-label');
        this.$price_min = $container.find('input[name=price_min]');
        this.$price_max = $container.find('input[name=price_max]');
        this.default_label = this.$label.text();
        $container.on('keyup', (e) => {
            this.render();
        });
        window.addEventListener("popstate", this.initFromQs.bind(this));
        this.initFromQs();
        this.render();
    }
    initFromQs() {
        let qs = queryString.parse(location.search);
        ['price_min', 'price_max'].forEach(k => {
            if (qs[k]) {
                this[`$${k}`].val(parseInt(qs[k], 10));
            }
        });
    }
    render() {
        let min = this.$price_min.val();
        let max = this.$price_max.val();
        if (min === '' && max === '') {
            return this.$label.addClass("-default").text(this.default_label);
        }
        else if (min !== '' && max === '') {
            return this.$label.removeClass("-default").text(`> $${min}`);
        }
        else if (min !== '' && max === '') {
            return this.$label.removeClass("-default").text(`< $${max}`);
        }
        else {
            return this.$label.removeClass("-default").text(`$${min} - $${max}`);
        }
    }
}
