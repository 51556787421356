import * as $ from 'jquery';
$('.js-open-chat').click(function () {
    $(this).fadeOut();
    $('.js-chat-bubble').fadeIn(400, function () {
        $(this).find('input[name=name]').focus();
    });
});
$('body').on('click', '.js-close-chat-bubble', function () {
    $('.js-chat-bubble').fadeOut();
    $('.js-open-chat').fadeIn();
});
