import * as $ from 'jquery';
import BodyOverflow from './plugins/body-overflow';
export const Header = {
    $header: $('.app-header'),
    $burger: $('.js-burger'),
    $body: $('body'),
    header_height: 0,
    init() {
        this.initScrollPastHeader();
        this.initNavMenu();
        $('.js-search-header-wrap').css("display", "flex").hide();
        $('.js-show-search').click(() => {
            $('.js-search-header-wrap').fadeIn();
            $('.js-search-header-wrap input').focus();
        });
        $('.js-header').on('click', ".js-close", function () {
            $('.js-search-header-wrap').fadeOut();
        });
    },
    initScrollPastHeader() {
        var last_known_scroll_position = 0;
        var ticking_scroll = false;
        var ticking = false;
        this.header_height = this.$header.height();
        window.addEventListener('scroll', (e) => {
            last_known_scroll_position = window.scrollY;
            if (!ticking_scroll) {
                window.requestAnimationFrame(() => {
                    this.handleScroll(last_known_scroll_position);
                    ticking_scroll = false;
                });
            }
            ticking_scroll = true;
        });
        let w_width = $(window).width();
        window.addEventListener('resize', (e) => {
            let new_width = $(window).width();
            if (!ticking && new_width !== w_width && BodyOverflow.overflown) {
                window.requestAnimationFrame(() => {
                    BodyOverflow.restore();
                    $('body').removeClass('nav-active');
                    ticking = false;
                });
            }
            w_width = new_width;
            ticking = true;
        });
    },
    initNavMenu() {
        this.$burger.click(() => {
            setTimeout(() => {
                this.$body.toggleClass('nav-active');
                if (this.$body.hasClass("nav-active")) {
                    BodyOverflow.fire();
                }
                else {
                    BodyOverflow.restore();
                }
            }, 100);
        });
    },
    handleScroll(scroll_position) {
        var y = $(document).scrollTop();
        if (y > this.header_height) {
            this.$body.addClass('-scrollPast');
        }
        else {
            this.$body.removeClass('-scrollPast');
        }
    }
};
