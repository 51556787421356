const Flickity = require('flickity');
require('flickity-imagesloaded');
try {
    if (document.querySelector('[data-flickity]')) {
        new Flickity('[data-flickity]', {
            accessibility: true,
            adaptiveHeight: false,
            autoPlay: false,
            cellAlign: 'left',
            cellSelector: undefined,
            contain: true,
            draggable: '>1',
            dragThreshold: 3,
            freeScroll: false,
            selectedAttraction: 0.2,
            friction: 0.8,
            groupCells: false,
            imagesLoaded: true,
            initialIndex: 0,
            lazyLoad: 4,
            percentPosition: false,
            prevNextButtons: true,
            pageDots: false,
            resize: true,
            rightToLeft: false,
            watchCSS: false,
            wrapAround: false
        });
    }
}
catch (e) {
}
