function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top - 80;
    var elemBottom = elemTop + $(elem).height();
    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
function onScroll() {
    $(".anim-scroll").each(function () {
        if (isScrolledIntoView($(this))) {
            $(this).addClass("animate-scroll");
        }
    });
}
export default {
    init() {
        $(window).on('scroll', function () {
            onScroll();
        });
        onScroll();
    }
};
