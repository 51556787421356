import * as $ from 'jquery';
function ssCycle($img, $el) {
    if ($img.next().length !== 0) {
        $img.addClass('inactive').removeClass('active');
        $img.next().removeClass('inactive').addClass('active');
        $el.data('timeouts').push(setTimeout(function () {
            ssCycle($img.next(), $el);
        }, 1750));
    }
    else {
        $img.addClass('inactive').removeClass('active');
        var $first = $($img.parent().children('img')[0]);
        $first.removeClass('inactive').addClass('active');
        $el.data('timeouts').push(setTimeout(function () {
            ssCycle($first, $el);
        }, 1750));
    }
}
function ssHoverIn() {
    var $this = $(this), $img = $this.children('.active');
    $this.data('timeouts', []);
    if ($this.children('img').length > 0) {
        $this.children('img').each(function () {
            var $this = $(this);
            if ($this.attr('src') === undefined) {
                $this.attr('src', $this.data('src'));
            }
        });
        $this.data('timeouts').push(setTimeout(function () {
            ssCycle($img, $this);
        }, 200));
    }
}
function ssHoverOut() {
    let $this = $(this);
    $.each($this.data('timeouts'), function (i, v) {
        clearTimeout(v);
    });
}
export default function () {
    if (!$('html').hasClass('touch')) {
        $('body').on('mouseenter', '.js-ajax-ss', ssHoverIn);
        $('body').on('mouseleave', '.js-ajax-ss', ssHoverOut);
    }
    $(".js-ajax-ss").each(function () {
        $($(this).children('img')[0]).addClass('active');
    });
}
