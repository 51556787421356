import * as $ from 'jquery';
import { UniqueId } from '../unique-id';
const queryString = require('query-string');
export class PropertiesController {
    constructor($container, $pagination, $positions, $properties, $map) {
        this.$loader = $(`<div class="ball-clip-rotate loader-wrap"><div></div></div>`);
        this.query_defaults = {
            CODE_STATUT: "",
            direction: "",
            order: "DATE_INSCRIPTION",
            MUN_CODE: "",
            NB_CHAMBRES: "",
            NB_SALLES_BAINS: "",
            page: 1,
            price_min: "0",
            price_max: "",
            s: ""
        };
        this.query = {};
        this.searching = false;
        this.search_timeout_length = 200;
        this.$container = $container;
        this.$map = $map;
        this.$pagination = $pagination;
        this.$positions = $positions;
        this.$properties = $properties;
        let $properties_search_field = $(".js-search-properties");
        if (!$('.app-nav__list.-social').is(':visible') || $('.js-burger').is(':visible')) {
            $properties_search_field.find('input').attr('placeholder', $properties_search_field.data('placeholder-mobile'));
        }
        $container.on('change:select', this.onChange.bind(this));
        $container.on('change', 'input[type=radio]', (e) => {
            let $c = $(e.target);
            this.onChange(e, {
                name: $c.attr("name"),
                value: $c.closest('.js-checkbox').find(":checked").val()
            });
        });
        $container.on('keyup', 'input[type=text], input[type=number]', (e, test) => {
            let $target = $(e.target);
            this.onChange(e, { name: $target.attr('name'), value: $target.val() });
        });
        $pagination.on('click', 'a', (e) => {
            let qs = $(e.target).attr('href');
            qs = qs.indexOf('?') !== -1 ? (qs.split('?').pop() || '') : '?page=1';
            this.handleUrlChange(qs);
            window.setTimeout(() => {
                var _a, _b;
                $('body, html').animate({
                    scrollTop: (_b = (_a = this.$container) === null || _a === void 0 ? void 0 : _a.offset()) === null || _b === void 0 ? void 0 : _b.top
                }, 'slow');
            }, 20);
            e.preventDefault();
            return false;
        });
        window.addEventListener("popstate", () => {
            this.last_request_id = '';
            this.handleUrlChange(location.search);
            this.initSearchFromQs();
        });
        this.initFromQs();
    }
    initFromQs() {
        let qs = queryString.parse(location.search);
        Object.keys(qs).forEach(key => {
            this.query[key] = qs[key];
        });
    }
    initSearchFromQs() {
        let qs = queryString.parse(location.search);
        if (qs.s) {
            $(`input[name=s]`).val(qs.s);
        }
    }
    handleUrlChange(url) {
        this.query = queryString.parse(url);
        this.renderLoader();
        this.search();
    }
    handleOrder(order) {
        let direction = "asc";
        if (order.indexOf("ZA") !== -1) {
            direction = "desc";
        }
        this.query = Object.assign(Object.assign({}, this.query), {
            direction,
            order
        });
    }
    handlePrice(price) {
        let parts = price.split(',');
        this.query.price_min = parts[0];
        if (parts[1])
            this.query.price_max = parts[1];
    }
    onChange(e, { name, value }) {
        if (name === "order") {
            this.handleOrder(value);
        }
        else {
            this.query[name] = value;
        }
        this.query.page = 1;
        this.renderLoader();
        clearTimeout(this.search_timeout);
        this.search_timeout = window.setTimeout(() => {
            this.search();
            this.search_timeout_length = 500;
        }, this.search_timeout_length);
    }
    prepareQuery() {
        return Object.keys(this.query).reduce((acc, k) => {
            if (this.query[k].length === 0) {
                return acc;
            }
            else if (this.query[k] === this.query_defaults[k]) {
                return acc;
            }
            return Object.assign(Object.assign({}, acc), { [k]: this.query[k] });
        }, {});
    }
    renderLoader() {
        this.$properties.html('');
        this.$pagination.html('');
        this.$positions.trigger('positions-clear');
        if (!this.$map.find('.loader').length) {
            this.$map.prepend(this.$loader);
        }
    }
    render(res) {
        res = res.replace(/^[\s\S]*<body.*?>|<\/body>[\s\S]*$/ig, '');
        var $html = $(res);
        if ($($html.find('.js-properties')).data('request-id') + '' !== this.last_request_id) {
            return false;
        }
        this.$map.find('.loader-wrap').remove();
        this.$properties.html($html.find('.js-properties').html());
        this.$pagination.html($html.find('.js-pagination').html());
        let positions = '';
        $html.each((i, el) => {
            let $el = $(el);
            if ($el.hasClass('js-positions')) {
                this.$positions.html($el.html());
            }
        });
        this.$positions.trigger('positions-refresh');
        return true;
    }
    search() {
        let q = this.prepareQuery();
        let id = UniqueId();
        this.last_request_id = id;
        let endpoint = '/search/properties';
        if (location.pathname.indexOf("/company") !== -1) {
            endpoint = location.pathname;
        }
        $.get(endpoint, Object.assign(Object.assign({}, q), { id }))
            .done(res => {
            let request_id_matches_last = this.render(res);
            if (!request_id_matches_last)
                return;
            let query = queryString.stringify(q);
            query = query === '' ? query : '?' + query;
            history.replaceState(null, '', query);
        })
            .fail(e => {
            this.$map.find('.loader-wrap').remove();
            this.searching = false;
        })
            .always(() => {
            this.search_timeout_length = 200;
        });
    }
}
