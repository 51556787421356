export const Agent = (agent) => `
  <li>
    <a href="${agent.url}">
      <div>
        <img src="${agent.img}">
      </div>
      <p>${agent.title}</p>
    </a>
  </li>
`;
export const Block = (lang, results, type) => `
  <h4>${lang.title}</h4>
  <ul class="sr__results -${type}">
    ${results}
  </ul>
`;
export const Property = (property) => `
    <li>
      <a href="${property.url}">
        <div class="search-results__address">
          <p class="-street">${property.address}</p>
          <p class="-city">${property.city}</p>
        </div>
        <div class="search-results__details">
          <p>
            <span>Beds:</span>
            ${property.beds}
          </p>
          <p>
            <span>Baths:</span>
            ${property.baths}
          </p>
          <p class="-price">${property.price}</p>
        </div>
      </a>
    </li>
`;
export const Wrap = (res) => `<div class="search-results">${res}</div>`;
