import * as $ from 'jquery';
export default {
    overflown: false,
    scroll_top: 0,
    fire() {
        this.scroll_top = $(window).scrollTop();
        $('html, body').css({
            overflow: 'hidden',
            margin: '0',
            height: '100%'
        });
        $(".js-content").css({
            'transform': 'translate(0, -' + (this.scroll_top) + 'px)'
        });
        this.overflown = true;
    },
    restore() {
        $('html, body').css({
            overflow: '',
            height: '',
            margin: '0'
        });
        $(".js-content").css({
            'transform': ''
        });
        $(window).scrollTop(this.scroll_top);
        this.overflown = false;
    }
};
